import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Treppen from "../queries/treppen"
import ServiceAndProcedure from "../components/serviceAndProcedure"

const Index = () => {
  const { sliderImage } = useStaticQuery(
    graphql`
      query {
        sliderImage: file(relativePath: { eq: "slider/header_treppe.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
      }
    `
  )
  const image = getImage(sliderImage)
  const bgImage = convertToBgImage(image)

  return (
    <Layout spacing="true">
      <Seo
        title="Treppen"
        description="Viele Wege führen nach oben und natürlich wieder runter. Mit uns stehen Ihnen alle Wege offen."
      />
      <div className="flex w-full h-screen">
        <BackgroundImage
          {...bgImage}
          style={{ width: "100%" }}
          className="h-full w-full flex items-center"
        >
          <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white mt-48 md:mt-72">
            <h1 className="text-4xl md:text-5xl font-bold mb-1.5">Treppen</h1>
            <p className="max-w-4xl text-xl mt-1">
              Viele Wege führen nach oben und natürlich wieder runter. Mit uns
              stehen Ihnen alle Wege offen.
            </p>
          </div>
        </BackgroundImage>
      </div>
      <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 bg-white">
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
          <div className="flex items-center justify-center lg:order-last">
            <img
              src="/zeichnungen/treppe.svg"
              className="block h-56"
              alt="Technische Zeichnung eines Balkons"
            />
          </div>
          <div className="flex items-center">
            <p>
              Es gibt viele interessante Kombinationen von Materialien im
              Treppenbau, welche die Möglichkeit bieten architektonische
              Verbindungen zum Wohnraum herzustellen, egal ob Innen oder Außen.
              Welche Konstruktion, welches Material und welches Geländer
              verwendet wird, entscheiden Sie! Alles wird individuell und nach
              Maß gefertigt. Hierbei spielt das Alter oder die Bauweise des
              Gebäudes keine Rolle.
            </p>
          </div>
        </div>
      </div>
      <ServiceAndProcedure />
      <div className="pb-16">
        <h2 className="w-full text-4xl md:text-5xl text-center mb-6 text-gray-800">
          Galerie
        </h2>
        <Treppen />
      </div>
    </Layout>
  )
}

export default Index
